import "./App.css";
import videoBg from "./bg.mp4";
import logo from "./logo.svg";
import bytizeLogo from "./bytize.svg";
import videoFallbackImg from "./bg.jpeg";

function App() {
  return (
    <div>
      <section className="relative h-screen">
        <div className="absolute top-0 left-0 right-0 bottom-0 h-screen overflow-hidden">
          <video
            src={videoBg}
            className="h-screen w-screen object-cover"
            muted
            autoPlay={true}
            playsInline
            loop="loop"
            poster={videoFallbackImg}
          ></video>
        </div>
        <div className="absolute top-0 left-0 right-0 bottom-0 h-screen bg-black opacity-35	"></div>
        <div className="absolute top-0 left-0 right-0 bottom-0 h-screen">
          <div className="flex flex-col space-y-2 items-center justify-center h-full p-4">
            <img src={logo} className="w-64" alt="logo" />
            <h1 className="text-2xl md:text-3xl text-white font-bold text-center">
              Inovo Holdings Ltd (DIFC)
            </h1>
            <p className="text-white text-xl text-center">
              Inovo is a pioneering technology firm that nurtures and grows
              innovative companies
            </p>
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <div className="flex flex-col space-y-2 items-center justify-center h-full py-8">
            <div className="scrolldown">
              <div className="chevrons">
                <div className="chevrondown"></div>
                <div className="chevrondown"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <header className="sticky top-0 p-4 shadow bg-white">
        <div className="container mx-auto">
          <div className="flex items-center gap-4">
            <img src={logo} className="h-8" alt="logo" />
            <div className="gap-6 mt-2 flex">
              {/* <div>
                <a
                  href="https://bytize.xyz"
                  target="_blank"
                  className="cursor-pointer font-bold hover:text-blue-600"
                  rel="noreferrer"
                >
                  Bytize
                </a>
              </div>
              <div>
                <a
                  href="https://posbytz.com"
                  target="_blank"
                  className="cursor-pointer font-bold hover:text-blue-600"
                  rel="noreferrer"
                >
                  PosBytz
                </a>
              </div> */}
              <div>
                <a
                  href="mailto:info@inovo.ltd"
                  className="cursor-pointer font-bold hover:text-blue-600"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="h-screen bg-[#4285F4]">
        <div className="flex h-full items-center justify-center">
          <div className="container mx-auto space-y-4 p-4">
            <h2 className="font-bold text-xl text-white uppercase">About Us</h2>
            <h3 className="font-bold text-5xl md:text-6xl md:w-1/2 text-white">
              Driving growth and progress through strategic investments in
              innovation.
            </h3>
          </div>
        </div>
      </div>
      <div className="h-screen bg-[#34A853]">
        <div className="flex h-full items-center justify-center">
          <div className="container mx-auto space-y-4 p-4">
            <h2 className="font-bold text-xl text-white uppercase">
              Technology
            </h2>
            <h3 className="font-bold text-5xl md:text-6xl md:w-1/2 text-white">
              Innovation enabled by the advancements and capabilities of
              Technology.
            </h3>
          </div>
        </div>
      </div>
      <div className="h-screen bg-[#EA4335]">
        <div className="flex h-full items-center justify-center">
          <div className="container mx-auto space-y-4 p-4">
            <h2 className="font-bold text-xl text-white uppercase">
              Investor Relations
            </h2>
            <h3 className="font-bold text-5xl md:text-6xl md:w-1/2 text-white">
              Committed to resilience, innovation, and reshaping marketplace.
            </h3>
          </div>
        </div>
      </div>
      <div className="h-screen bg-[#FBBC05]">
        <div className="flex h-full items-center justify-center">
          <div className="container mx-auto space-y-4 p-4">
            <h2 className="font-bold text-xl text-white uppercase">
              Inovo Businesses
            </h2>
            <h3 className="font-bold text-5xl md:text-6xl md:w-1/2 text-white">
              Our teams are empowered to explore and drive innovation.
            </h3>
          </div>
        </div>
      </div>
      <div className="h-screen bg-[#A142F4]">
        <div className="flex h-full items-center justify-center">
          <div className="container mx-auto space-y-4 p-4">
            <h2 className="font-bold text-xl text-white uppercase">
              Career Growth
            </h2>
            <h3 className="font-bold  text-5xl md:text-6xl md:w-1/2 text-white">
              More than just an excellent start, an innovative launch to your
              career.
            </h3>
          </div>
        </div>
      </div>
      <footer className="p-6 bg-black">
        <div className="container mx-auto space-y-4">
          <h4 className="uppercase text-white font-bold">Our Companies</h4>
          <div className="grid md:grid-cols-3 gap-4">
            <div className="space-y-2">
              <img src={logo} className="h-10" alt="logo" />
              <p className="text-white font-bold">Inovo Holdings Ltd. (DIFC)</p>
              <p className="text-white">DIFC, Dubai, UAE</p>
              <a
                href="mailto:info@inovo.ltd"
                className="text-blue-500 underline"
              >
                info@inovo.ltd
              </a>
            </div>
            {/* <div className="space-y-2">
              <img src={bytizeLogo} className="h-10" alt="bytize logo" />
              <p className="text-white font-bold">
                Bytize Technology Solutions LLC.
              </p>
              <p className="text-white">Mainland, Dubai, UAE</p>
              <a
                href="mailto:info@bytize.xyz"
                className="text-blue-500 underline"
              >
                info@bytize.xyz
              </a>
            </div>
            <div className="space-y-2">
              <img src={bytizeLogo} className="h-10" alt="bytize logo" />
              <p className="text-white font-bold">
                Bytize Technology Solutions Private Limited.
              </p>
              <p className="text-white">Chennai, Tamil Nadu, India</p>
              <a
                href="mailto:info@bytize.xyz"
                className="text-blue-500 underline"
              >
                info@bytize.xyz
              </a>
            </div> */}
          </div>
          <div className="text-white text-sm">
            &copy; {new Date().getFullYear()} Inovo Holdings Ltd. All Rights
            Reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
